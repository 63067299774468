import loading from '@/utils/loading.json'
import * as hostsApi from '@/services/hosts'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import { mixin } from '@/mixins/mixin'
import * as hostAddressesApi from '@/services/host-addresses'
import { deleteHostAddresses } from '@/services/host-addresses'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'

export default {
  mixins: [mixin],
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect,
    allButton,
    searchForm
  },
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      basicViewItem: jsonData.basicViewItem,
      historyTableItem: history.historyTableItem,
      tableDataItem: jsonData.tableDataItem,
      dialogData: [],
      batchData: [],
      dialogVisible: false,
      historyTableData: []
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
    _this.queryData(hostAddressesApi.getSetup)
    this.search()
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 初始化
    async init () {
      const _this = this
      _this.setGlobalLoading(true)
	  const res = await hostsApi.getHostsId(_this.urlParams.id)
	  if (res.status === 200) {
		  _this.$message({
		    type: 'success',
		    message: _this.$t('message.loadSuccess')
		  })
		  _this.custName = res.data.host
		  Object.assign(this.titleObject, { title: '', data: '' })
	  }
	  _this.setGlobalLoading(false)
      //  获取历史操作
      getHistoryList({ item_type: 'Host', item_id: _this.urlParams.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    // 点击操作按钮
    async handleInnerClick (type, data) {
      const _this = this
      if (type?.action === 'view') {
        _this.$router.push({
          name: 'buddyManagerAddressDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        _this.$router.push({
          name: 'buddyManagerAddressEdit',
          params: {
            id: data.id.value,
            buddy: _this.urlParams.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteHostAddresses)
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.searchData.host_id_eq = this.$route.params.id
      this.loading = true
      hostAddressesApi.getHostAddresses(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleEditClick () {
      this.$router.push({
        name: 'buddyManagerBuddyEdit',
        params: {
          id: this.$route.params.id
        }
      })
    }
  }
}
