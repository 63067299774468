export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'whatsapp_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'wechat_id',
    label: '',
    Object: 'value'
  }, {
    prop: 'avatar',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'ic_front_image',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'ic_back_image',
    label: '',
    image: true,
    span: 24
  }, {
    prop: 'online',
    label: '',
    Object: 'value'
  }, {
    prop: 'zero_commission',
    label: '',
    Object: 'value'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'deleted_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'approval_status',
    label: '',
    Object: 'value'
  }, {
    prop: 'user',
    label: '',
    Object: 'value'
  }, {
    prop: 'current_sign_in_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'last_sign_in_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'current_sign_in_ip',
    label: '',
    Object: 'value'
  }, {
    prop: 'last_sign_in_ip',
    label: '',
    Object: 'value'
  }, {
    prop: 'host_code',
    label: '',
    Object: 'value'
  }, {
    prop: 'host_password',
    label: '',
    Object: 'value'
  }, {
    prop: 'supported_product_types',
    label: '',
    Object: 'value'
  }, {
    prop: 'supported_product_reported_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'current_total_sales',
    label: '',
    Object: 'value'
  }, {
    prop: 'sales_percentile',
    label: '',
    Object: 'value'
  }, {
    prop: 'whatsapp_group_link',
    label: '',
    Object: 'value'
  }, {
    prop: 'had_past_experience',
    label: '',
    Object: 'value'
  }, {
    prop: 'email',
    label: '',
    Object: 'value'
  }, {
    prop: 'bank_name',
    label: '',
    Object: 'value'
  }, {
    prop: 'account_name',
    label: '',
    Object: 'value'
  }, {
    prop: 'account_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'paynow_number',
    label: '',
    Object: 'value'
  }, {
    prop: 'bank_swift_code',
    label: '',
    Object: 'value'
  }, {
    prop: 'zoho_customer_id',
    label: '',
    Object: 'value'
  }, {
    prop: 'combine_v1_host_id',
    label: '',
    Object: 'value'
  }, {
    prop: 'birthday',
    label: '',
    Object: 'value'
  }, {
    prop: 'citizen',
    label: '',
    Object: 'value'
  }, {
    prop: 'source',
    label: '',
    Object: 'value'
  }, {
    prop: 'note',
    label: '',
    Object: 'value',
    span: 24
  }],
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'image',
    label: '',
    img: true,
    width: '120'
  }, {
    prop: 'host',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'address',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'postal_code',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'address_type',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
