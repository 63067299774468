<template>
  <div class="managerDeliveryOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn>
        <el-button type="primary" size="mini" @click="handleEditClick">{{ $t('text.edit') }}</el-button>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{ $t('text.basicInfo') }}</span>
              <el-card class="animate__animated animate__fadeInDown" shadow="hover">
                <custome-table tableType="object" :custSpan="6" :data="custName.basic_information" :dataItem="basicViewItem"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('menu.buddyAdreess')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                <search-form ref="searchForm" :topVisible="false" :tabData="tabData" labelWidth="120px">
                </search-form>
                 <span class="view__text"></span>
                 <create-table :operationColWidth="150" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleInnerClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort">
                   <template #topButton>
                     <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
                     <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
                   </template>
                   <template #rightBtn="scope">
                     <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleInnerClick(item, scope.row)">{{item.name}}</el-button>
                     <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
                   </template>
                 </create-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{ $t('form.common.changeHistory') }}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                <span class="view__text"></span>
                <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
</script>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
</style>
